import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu" ]

  open() {
    if (this.menuTarget.style.opacity !== '1') {
      this.menuTarget.style.opacity = 1
      this.menuTarget.style.transform = "scaleY(1)"
    } else {
      this.close()
    }
  }

  close() {
    this.menuTarget.style.opacity = 0
    this.menuTarget.style.transform = "scaleY(0)"
  }
}
