import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "classHolder" ]

  expand() {
    this.classHolderTarget.classList.remove('collapsed')
    this.classHolderTarget.classList.add('expanded')
  }

  collapse() {
    this.classHolderTarget.classList.remove('expanded')
    this.classHolderTarget.classList.add('collapsed')
  }
}
