import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "buttonContainer", "resultForm", "resultSubscriptionId", "resultPlanId", "paymentReceived" ]

  static values = {
    userExternalId: String,
    paymentSuccessfulUrl: String,
  }

  connect() {
    const button = this.createPayPalButton();

    // PayPal button is no longer available when
    // waiting for payment info to be received via webhook
    if (this.hasButtonContainerTarget) {
      button.render('#paypal-button-container');
    }
  }

  createPayPalButton() {
    const component = this;

    return paypal.Buttons({
      createSubscription: function (data, actions) {
        console.log("Creating subscription for " + component.planId())

        return actions.subscription.create({
          plan_id: component.planId(),
          custom_id: component.userExternalIdValue,
        });
      },

      onApprove: function (data, _actions) {
        component.renderSuccess(data.subscriptionID, component.planId())
      }
    });
  }

  renderSuccess(subscriptionId, planId) {
    this.resultSubscriptionIdTarget.value = subscriptionId;
    this.resultPlanIdTarget.value = planId;
    this.resultFormTarget.requestSubmit();
  }

  paymentReceivedTargetConnected(_target) {
    window.location.href = this.paymentSuccessfulUrlValue
  }

  planId() {
    let planId = "not found";

    this.inputTargets.forEach(function(input) {
      if (input.checked) {
        planId = input.value;
      }
    });

    return planId;
  }
}
