import { Controller } from "@hotwired/stimulus"

class BaseContactFormController extends Controller {
  static values = {
    formId: String,
    useLiveRoiPayload: Boolean,
  }

  static targets = [
    // container target is used for finding input fields
    "container",

    // disabling submit/enabling spinner
    "submit",
    "submitLabel",
    "submitSpinner",

    // hiding for after successful submit
    "form",

    // displaying success/errors
    "postSubmit",
    "error"
  ]

  activateSubmitButton() {
    this.submitTarget.disabled = !this.isValid();
  }

  submit(event) {
    this.submitLabelTarget.style.opacity = 0;
    this.submitSpinnerTarget.style.opacity = 1;

    this.sendData();

    event.preventDefault()
  }

  isValid() {
    const fn = this.getInput("firstname").value.length >= 2;
    const ln = this.getInput("lastname").value.length >= 2;
    const em = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(this.getInput("email").value);
    const co = this.getInput("consent_gdpr")?.checked || this.getInput("agree")?.checked;

    return fn && ln && em && co;
  }

  getInput(name, type = "input") {
    return this.containerTarget.querySelector(`${type}[name=${name}]`)
  }

  formUrl() {
    return 'https://api.hsforms.com/submissions/v3/integration/submit/7430911/' + this.formIdValue
  }

  showSuccess() {
    this.postSubmitTarget.innerHTML = 'Thank you for your interest - we will get in touch with you shortly!';
    this.postSubmitTarget.style.opacity = 1;
  }

  showError() {
    this.submitLabelTarget.style.opacity = 1
    this.submitSpinnerTarget.style.opacity = 0
    this.activateSubmitButton()
    this.errorTarget.innerHTML = 'Sorry, there was an error. Please try again later.'
  }

  getFields() {
    throw new Error('Not implemented');
  }

  buildPayload() {
    let data = {
      "submittedAt": new Date().getTime(),
      "fields": this.getFields(),
      "context": { "pageUri": window.location.href, "pageName": "Pulse Website" },
      "legalConsentOptions": {
        "consent": {
          "consentToProcess": this.getInput("consent_gdpr")?.checked || this.getInput("agree")?.checked,
          "text": this.useLiveRoiPayloadValue ?
            "By registering, you agree that we may process and store your personal data (name, email address) in accordance with these terms, and you accept our Terms of Service." :
            "I agree to allow TD Reply to store and process my personal data.",
          "communications": [{
            "value": true,
            "subscriptionTypeId": 8951742,
            "text": this.useLiveRoiPayloadValue ?
              "I agree to receive email updates and information on products, services, news and events from TD Reply." :
              "In order to provide you the content requested, we need to store and process your personal data."
          }]
        }
      }
    }

    if (this.getInput("consent_marketing", "checkbox")?.checked || this.getInput("agree", "checkbox")?.checked) {
      data.legalConsentOptions.consent.communications.push({
        "value": true,
        "subscriptionTypeId": 8924400,
        "text": this.useLiveRoiPayloadValue ?
          "I agree to receive email updates and information on products, services, news and events from TD Reply." :
          "I agree to receive marketing offers and updates from TD Reply."
      });
    }

    return data
  }

  sendData() {
    if (!this.isValid()) {
      this.activateSubmitButton()
      return
    }

    let that = this

    fetch(this.formUrl(), {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(this.buildPayload())
    }).then(function(response) {
      if (response.ok) {
        that.showSuccess()
      } else {
        that.showError()
      }
    }).catch(function(error) {
      console.log(error)
      that.showError()
    })
  }
}

class ContactFormController extends BaseContactFormController {
  static targets = [ "lightbox" ]

  open(event) {
    this.containerTarget.style.visibility = 'visible';
    this.containerTarget.style.opacity = 1;
    this.lightboxTarget.style.visibility = 'visible';
    this.lightboxTarget.style.opacity = 0.8;

    this.activateSubmitButton()

    event.preventDefault();
  }

  close() {
    let lightbox = this.lightboxTarget
    let container = this.containerTarget

    lightbox.style.opacity = 0;
    container.style.opacity = 0;

    setTimeout(function() {
      lightbox.style.visibility = 'hidden';
      container.style.visibility = 'hidden';
    }, 700);
  }

  getFields() {
    return [
      { "name": "email", "value": this.getInput("email").value },
      { "name": "firstname", "value": this.getInput("firstname").value },
      { "name": "lastname", "value": this.getInput("lastname").value },
    ]
  }

  showSuccess() {
    this.formTarget.style.opacity = 0;

    super.showSuccess()

    let that = this

    setTimeout(function() {
      that.close()
    }, 3500);
  }
}

class LiveROIFormController extends BaseContactFormController {
  connect() {
    this.activateSubmitButton()
  }

  getFields() {
    return [
      { "name": "email", "value": this.getInput("email").value },
      { "name": "firstname", "value": this.getInput("firstname").value },
      { "name": "lastname", "value": this.getInput("lastname").value },
      { "name": "company", "value": this.getInput("company").value },
      { "name": "jobtitle", "value": this.getInput("position").value },
      { "name": "comment", "value": this.getInput("comment", "textarea").value },
    ]
  }

  showSuccess() {
    super.showSuccess();

    this.formTarget.style.display = "none";
  }
}

export { ContactFormController, LiveROIFormController }
