import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    returnUrl: String,
    frameId: String,
  }

  close() {
    let element = this.element;

    if (this.frameIdValue) {
      element = document.querySelector(this.frameIdValue);
    }

    element.src = this.returnUrlValue;
  }
}
