import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "link" ]

  static values = {
    url: String,
    turboFrame: String,
  }

  redirect() {
    if (this.urlValue && this.turboFrameValue) {
      let frame = document.querySelector(`turbo-frame#${this.turboFrameValue}`)
      frame.src = this.urlValue
    } else if (this.urlValue) {
      window.location.href = this.urlValue;
    } else if (this.hasLinkTarget) {
      this.linkTarget.click()
    }
  }
}
