import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "page1", "page2", "company", "companyVatId",
                     "addressAdditional", "addressAdditionalLabel" ]

  connect() {
    if (this.errorsCount(this.page1Target) === 0 &&
        this.errorsCount(this.page2Target) > 0) {
      this.nextPage()
    }

    this.toggleCompanyVatId()
    this.toggleAddressAdditional(null, true)
  }

  toggleCompanyVatId() {
    if (this.formValue(this.companyTarget) !== "") {
      this.showTarget(this.companyVatIdTarget)
    } else {
      this.hideTarget(this.companyVatIdTarget)
      this.setFormValue(this.companyVatIdTarget, "")
    }
  }

  toggleAddressAdditional(event, initial) {
    if (!initial) {
      this.addressAdditionalLabelTarget.textContent =
        this.addressAdditionalLabelTarget.textContent.substring(2)
    }

    if ((initial && this.addressAdditionalTarget.value !== "") ||
        (!initial && this.addressAdditionalTarget.style.display === 'none')) {
      this.addressAdditionalLabelTarget.textContent =
        '- ' + this.addressAdditionalLabelTarget.textContent

      this.showTarget(this.addressAdditionalTarget)
    } else {
      this.addressAdditionalLabelTarget.textContent =
        '+ ' + this.addressAdditionalLabelTarget.textContent

      this.hideTarget(this.addressAdditionalTarget)
      this.addressAdditionalTarget.value = ""
    }
  }

  errorsCount(target) {
    return target.querySelectorAll('span.error').length
  }

  formValue(target) {
    return target.querySelector("input").value
  }

  setFormValue(target, value) {
    return target.querySelector("input").value = value
  }

  nextPage() {
    this.hideTarget(this.page1Target)
    this.showTarget(this.page2Target)
  }

  prevPage() {
    this.showTarget(this.page1Target)
    this.hideTarget(this.page2Target)
  }

  hideTarget(target) {
    target.style.display = 'none'
  }

  showTarget(target) {
    target.style.display = 'block'
  }
}
